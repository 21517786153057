import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const LadiesPage = () => (
  <Layout>
    <Seo title="OC Beach Wax for the Ladies" description="OC Beach Wax is located in Ocean City, MD, and offers a variety of services for the ladies including waxing, facials, and lashes." />
    <div className="row justify-content-center page-header">
        <div className="stripers">&nbsp;</div>
        <div className="col-11 col-md-4 col-xl-3">
            <h1>Waxing &amp; Facials for the Ladies</h1>
            <p>Body waxing and facials are popular beauty treatments for women that can help enhance their appearance and promote self-care.</p>
        </div>
        <div className="col-11 col-md-8 col-lg-6 col-xl-5">
        <StaticImage      
            className="collage-img-wrap"
            imgClassName="collage-img img-fluid"
            loading="eager" 
            alt="waxed ladies on the beach"
            src={
            "../images/women-collage1.png"
            }
            objectFit="contain"        
            objectPosition="50% 50%"
            
        />
        </div>
    </div>
    <div className="row justify-content-center page-content">
        <div className="col-12 col-md-7 page-section">
            <div className="page-section-text">
            <h3>Body Waxing</h3>   
                <p>Body waxing involves the removal of body hair using heated wax. The wax is applied to the desired area and then quickly removed, pulling out the hair from the root. 
                    Common areas for body waxing include the legs, arms, underarms, bikini area, and facial hair. </p>
                    <p>Our team has a lot of experience working with female clients and focuses on reducing discomfort as much as possible. 
                    We use appropriate waxing techniques and products to ensure efficient and relatively comfortable hair removal. 
                    Although we'll be sure to ask, please make sure we know if there's a certain area or level of removal you're looking for.
                    </p>
                    <p>
                    It's typically recommended to let the hair grow to a certain length (usually about a quarter of an inch) before getting waxed for best results.<br /><br />
                    Some benefits include:
                    </p>
                    <ul>
                <li>Longer-lasting results compared to shaving.</li>
                <li>Smoother skin as waxing removes dead skin cells along with the hair.</li>
                <li>Hair regrowth is often softer and finer over time.</li>
                <li>Reduced risk of cuts and razor burn.</li>
                </ul>
                <h3>Facials for Women</h3>
                <p>Facials are rejuvenating skincare treatments that are tailored to address specific skin concerns and improve overall complexion. 
                    They typically involve a series of steps such as cleansing, exfoliation, extraction, massage, masks, and moisturization. </p>
                    <p>Different types of facials are available, targeting various skin concerns like acne, aging, dryness, or sensitivity. 
                        Our professional estheticians can assess your skin type and recommend the most suitable facial treatment.</p>
            </div>
        </div>
        <div className="col-12 col-md-7 col-lg-5 page-section">
            <div className="pricing">
                <h2>Waxing</h2>                   
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Arm Full</div><div className="col-3 col-lg-2 ">35</div></div>
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Arm Half</div><div className="col-3 col-lg-2 ">25</div></div>
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Back Full</div><div className="col-3 col-lg-2 ">50+</div></div>
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Back Half</div><div className="col-3 col-lg-2 ">25</div></div>
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Bikini</div><div className="col-3 col-lg-2 ">35+</div></div>
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Brazilian</div><div className="col-3 col-lg-2 ">65+</div></div>
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Chin</div><div className="col-3 col-lg-2 ">15</div></div>
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Eyebrow</div><div className="col-3 col-lg-2 ">20+</div></div>      
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Eyebrow Tint</div><div className="col-3 col-lg-2 ">25</div></div>
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Eyebrow Tweezing</div><div className="col-3 col-lg-2 ">30</div></div>
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Eyebrow with Tint (Brow or Lash)</div><div className="col-3 col-lg-2 ">30</div></div>
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Eyelash Tint</div><div className="col-3 col-lg-2 ">20</div></div>
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Face</div><div className="col-3 col-lg-2 ">50</div></div>               
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Feet and Toes</div><div className="col-3 col-lg-2 ">10</div></div>
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Legs Full</div><div className="col-3 col-lg-2 ">70</div></div>
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Legs Half</div><div className="col-3 col-lg-2 ">45</div></div>
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Lip</div><div className="col-3 col-lg-2 ">12</div></div>
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Lip, Chin and Brow Combo</div><div className="col-3 col-lg-2 ">40</div></div>
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Patch</div><div className="col-3 col-lg-2 ">15</div></div>
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Underarms</div><div className="col-3 col-lg-2 ">20</div></div>
                <h2>Facials</h2>
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Anti-Aging Facial</div><div className="col-3 col-lg-2 ">85/65 mins</div></div>
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Seasonal Skin Facial</div><div className="col-3 col-lg-2 ">75/55 mins</div></div>  
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Backcial (a facial for your back)</div><div className="col-3 col-lg-2 ">65</div></div>                                 
                        
            </div>
      </div>
    </div>
    
  </Layout>
)

export default LadiesPage
